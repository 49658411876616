<template>
  <v-form ref="form" v-if="!!formSpecification && !!data">
    <pre v-if="debug">InitialData: {{ initialData }}</pre>
    <pre v-if="debug">InternalModel: {{ model }}</pre>
    <pre v-if="debug">Data sent to server: {{ mutationVars }}</pre>
    <pre v-if="debug">Data from gql: {{ entity }}</pre>
    <pre v-if="debug">Errors from gql: {{ errors }}</pre>
    <slot name="form-top"></slot>
    <v-alert type="error" v-if="serverErrors.length > 0">
      Wystąpił błąd serwera, odśwież stronę i spróbuj ponownie za chwilę. <br /><span
        v-if="serverErrors[0].networkError.result.errors[0].message"
        >( {{ serverErrors[0].networkError.result.errors[0].message }} )</span
      >
    </v-alert>
    <v-alert type="error" v-if="globalErrors.length > 0">
      <p v-for="error in globalErrors" :key="error.message">
        {{ error.message }}
      </p>
    </v-alert>
    <v-row class="mx-2" v-for="(row, idx) in formFields" :key="idx">
      <v-col
        cols="12"
        :md="field.cols.md || 12"
        :xs="field.cols.xs || field.cols.md || 12"
        :sm="field.cols.sm || field.cols.md || 12"
        :lg="field.cols.lg || field.cols.md || 12"
        :xl="field.cols.xl || field.cols.md || 12"
        v-for="(field, idxCol) in withoutSkipped(row.columns)"
        :key="idxCol"
      >
        <slot :name="`field.pre.${field.model.out}`" v-bind:field="field" />
        <component
          :ref="field.model.out"
          cols="auto"
          :rules="getRulesForField(field)"
          v-bind:is="field.type"
          outlined
          v-model="model[field.model.out]"
          :disabled="isComponentDisabled(field.props) || disabled || globalLoading"
          v-bind="extractProps(field.props)"
          v-on:keyup.enter="field.onEnterPressed ? field.onEnterPressed() : () => {}"
          :loading="globalLoading"
          v-on="field.listeners || {}"
          :form-values="model"
          @updateFormValues="updateFormValues"
        />
        <slot :name="`field.post.${field.model.out}`" v-bind:field="field" />
        <!--            @change="validate(field.model.out)"-->
        <!--            @input="clearAndValidate(field.model.out)"-->
      </v-col>
    </v-row>
    <slot name="form-bottom"></slot>
  </v-form>
  <div class="text-center" v-else>
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>
<script>
import { VTextField, VTextarea } from 'vuetify/lib';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import { flatten } from '@/helpers/flattenObject';
import { translateServerSideCode } from '@/helpers/validators';
import getByPath from '@/helpers/dotNotation';

export default {
  name: 'AbstractForm',
  components: { VTextField, VTextarea },
  watch: {
    entity() {
      this.model = this.initialData;
    },
  },
  mounted() {
    this.model = this.initialData;
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  },
  props: {
    mutation: {
      type: Object,
      required: false,
    },
    refetchQueries: {
      type: Array,
      required: false,
      default: () => [],
    },
    debug: {
      type: Boolean,
      required: false,
    },
    /**
     * if is provided, wil serve as base data. Usefull when you donot want to create query
     * to provide base data
     */
    entityData: {
      type: Object,
      required: false,
      default: () => null,
    },
    formSpecification: {
      type: Object,
      required: true,
    },
    mutationVariables: {
      type: Function,
      // eslint-disable-next-line
      default: (data, entityId) => {
        return { input: data };
      },
    },
    mutationErrorMapper: {
      type: Function,
      require: false,
      default: (data) => {
        console.error('You need to provide mutationErrorMapper prop');
        console.error('Got response from server: ', data);
        return [];
      },
    },
    // required only for edits
    entityId: {
      type: String,
      required: false,
    },
    query: {
      type: Object,
      required: false,
      default: null,
    },
    queryUpdate: {
      type: Function,
      default: (data) => {
        return data;
      },
    },
    queryVariables: {
      type: Function,
      default: (entityId) => {
        return { entityId };
      },
    },
  },
  data: () => {
    return {
      lastResponse: null,
      model: {},
      serverErrors: [],
      errors: [],
      loading: false,
      entity: false,
    };
  },
  methods: {
    isComponentDisabled(props) {
      const extracted = this.extractProps(props);
      return extracted?.disabled || false;
    },
    extractProps(props) {
      if (_.isFunction(props)) {
        return props({ model: this.model });
      }
      return props;
    },
    calculateInitialData() {
      let baseData = {};
      if (this.entityData !== null) {
        baseData = this.entityData;
      }

      if (this.entity) {
        baseData = this.entity;
      }

      const baseDataFlattened = flatten(baseData);

      const returnData = {};
      for (const row of this.formSpecification.fields) {
        for (const column of row.columns) {
          const initialDataKey = column.model.in || column.model.out;
          returnData[column.model.out] = null;

          // if initial data set, set as default value
          if (column.initialData) {
            returnData[column.model.out] = column.initialData();
          }

          // if loaded entity has key, overload default value with loaded one
          if (Object.prototype.hasOwnProperty.call(baseData, initialDataKey)) {
            returnData[column.model.out] = baseData[initialDataKey];
          }

          if (Object.prototype.hasOwnProperty.call(baseDataFlattened, initialDataKey)) {
            returnData[column.model.out] = baseDataFlattened[initialDataKey];
          }

          let modifier = (input) => input;

          if (column.inputModifier) {
            modifier = column.inputModifier;
          }

          // apply input modifier
          returnData[column.model.out] = modifier(returnData[column.model.out]);
        }
      }
      return returnData;
    },
    parseInitialData(data) {
      return data;
    },
    clear() {
      this.$refs.form.resetValidation();
      this.model = this.calculateInitialData();
    },
    updateFormValues(newValues) {
      for (const key in newValues) {
        if (!Object.prototype.hasOwnProperty.call(this.model, key)) {
          continue;
        }
        this.model[key] = newValues[key];
      }
    },
    clearServerSideErrors() {
      this.errors = [];
    },
    clearAndValidate() {
      this.clearServerSideErrors();
      this.validate();
    },
    validate() {
      this.validateForm();
      this.clearServerSideErrors();
    },
    validateForm() {
      this.$refs.form.validate();
    },
    // this function returns validator function that will be ran each validation
    // eslint-disable-next-line
    generateServersideErrorsValidator(field, value) {
      // eslint-disable-next-line
      return (inputValue) => {
        const errors = this.getServerErrorsForField(field);
        if (errors.length === 0) {
          return true;
        }
        // example: errors = ["SECURITY_INVALID_USERNAME"]
        // return only first error ( for now ), for example "SECURITY_INVALID_USERNAME"
        return translateServerSideCode(errors[0].code) || `Wystąpił błąd: ${errors[0].message}`;
      };
    },
    withoutSkipped(data) {
      return data.filter((col) => {
        if (_.isFunction(col.skip)) {
          return !col.skip({ model: this.model, entity: this.entity });
        }
        return !col.skip;
      });
    },
    getRulesForField(field) {
      if (field.rules) {
        return [...field.rules, this.generateServersideErrorsValidator(field)];
      }
      return [this.generateServersideErrorsValidator(field)];
    },
    getServerErrorsForField(field) {
      if (typeof this.errors !== 'object') {
        return [];
      }
      if (this.errors.length === 0) {
        return [];
      }
      const errors = [];
      // get all serverside errors ( this.errors ) and iterate over them
      for (const error of this.errors) {
        // if field is error.field - push to return array
        if (field.model.out === error.field) {
          errors.push(error);
        }
      }
      return errors;
    },
    unFlatten(data) {
      /* eslint-disable */
      const result = {};
      for (var i in data) {
        var keys = i.split('.');
        keys.reduce(function (r, e, j) {
          return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? data[i] : {}) : []);
        }, result);
      }
      /* eslint-enable */
      return result;
    },
    outputData(dataInput) {
      const data = dataInput;
      for (const row of this.formSpecification.fields) {
        for (const column of row.columns) {
          if (column.outputModifier) {
            const modifier = column.outputModifier;
            // eslint-ignore-next-line
            data[column.model.out] = modifier(data[column.model.out]);
          }
        }
      }

      return data;
    },
    grabDebugVariables() {
      return this.mutationVariables(this.outputData(this.unFlatten(this.model)), this.entityId);
    },
    makeSmartQueryConfig() {
      return {
        query: this.query,
        variables() {
          return this.queryVariables(this.entityId);
        },
        update: this.queryUpdate,
      };
    },
    async mutate() {
      this.lastResponse = null;
      this.validate();
      if (!this.$refs.form.validate()) {
        return;
      }
      this.errors = [];
      this.serverErrors = [];
      this.loading = true;
      this.$emit('loading', true);
      const data = this.mutationVariables(this.outputData(this.unFlatten(this.model)), this.entityId);
      await this.$apollo
        .mutate({
          mutation: this.mutation,
          variables: data,
          refetchQueries: this.refetchQueries,
          update: (store, serverData) => {
            this.lastResponse = serverData;
            this.loading = false;
            this.$emit('loading', false);
            this.errors = this.mutationErrorMapper(serverData);
            const hasServerSideErrors = this.errors.length > 0;
            // eslint-disable-next-line
            this.validateForm();
            this.$emit('updateStore', { store, serverData, hasServerSideErrors });
            this.$emit('update', serverData);
            if (!hasServerSideErrors) {
              this.$emit('done', serverData);
              this.clear();
            }
          },
        })
        .catch((e) => {
          this.$emit('loading', false);
          this.loading = false;
          this.serverErrors.push(e);
          this.$emit('server-error', e);
        });
    },
  },
  computed: {
    globalErrors() {
      return this.errors.filter((error) => error.__typename === 'GlobalFormErrorType');
    },
    formFields() {
      const data = [];

      for (const field of this.formSpecification.fields) {
        if (!field.skip) {
          data.push(field);
        }
      }
      return data;
    },
    disabled() {
      return this.loading;
    },
    isEdit() {
      return !!this.entityId;
    },
    globalLoading() {
      return this.loading || (this.$apollo.queries.entity && this.$apollo.queries.entity.loading);
    },
    mutationVars() {
      return this.grabDebugVariables();
    },
    data: {
      get() {
        const returnData = {};
        for (const row of this.formSpecification.fields) {
          for (const column of row.columns) {
            if (column.dontSend) {
              continue;
            }
            returnData[column.model.out] = null;
            if (this.entity) {
              returnData[column.model.out] = getByPath(
                column.model.in || column.model.out,
                this.entity,
                column.model.default || null,
              );
            }
          }
        }
        return returnData;
      },
      set() {},
    },
    initialData() {
      return this.calculateInitialData();
    },
  },
  created() {
    if (!this.isEdit) {
      return;
    }
    this.$apollo.addSmartQuery('entity', this.makeSmartQueryConfig());
  },
};
</script>
