export const isRequiredValidator = (value) => {
  return !!value || 'To Pole jest wymagane';
};
export const isNumberValidator = (value) => {
  return Number.isInteger(parseInt(value, 10)) || 'Podaj liczbę';
};
export const emailValidator = (value) => /.+@.+\..+/.test(value) || 'Podaj prawidłowy adres e-mail';
export const noteTitleLengthValidator = (value) =>
  /^.{1,50}$/.test(value) || 'Wystąpił błąd: dozwolona ilość znaków to: 50 lub mniej znaków';
export const noteContentLengthValidator = (value) =>
  /^.{1,500}$/.test(value) || 'Wystąpił błąd: dozwolona ilość znaków to: 500 lub mniej znaków';
export const testValidator = () => 'To jest testowy walidator który zawse zwraca błąd.';

export const translateServerSideCode = (code) => {
  const SERVER_SIDE_ERROR_CODES = {
    SECURITY_INVALID_USERNAME: 'Nieprawidłowy login',
    SECURITY_INVALID_PASSWORD: 'Nieprawidłowe hasło',
    CONSTRAINT_NOT_EMAIL: 'Nieprawidłowy email',
    // GENERIC: 'Nieprawidłowa wartość',
  };

  return SERVER_SIDE_ERROR_CODES[code] || null;
};
